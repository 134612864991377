import "@/bootstrap";
import formkitTheme from "@/formkitTheme";
import dayjs from "@/plugins/dayjs";
import flare from "@/plugins/flare";
import { ZiggyVue } from "@/plugins/ziggy";
import { Ziggy } from "@/ziggy";
import { flareVue } from "@flareapp/vue";
import { createAutoAnimatePlugin } from "@formkit/addons";
import { nl } from "@formkit/i18n";
import { generateClasses } from "@formkit/themes";
import { defaultConfig, plugin as FKp } from "@formkit/vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createPinia } from "pinia";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import { createApp, h } from "vue";

if (import.meta.env.PROD) {
    flare.light();
}

createInertiaApp({
    progress: {
        delay: 250,
        color: "#000"
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const currentLocale = props.initialPage.props.locale;

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, { ...Ziggy, locale: currentLocale })
            .use(dayjs, { locale: currentLocale })
            .use(createPinia())
            .use(VCalendar)
            .use(flareVue)
            .use(
                FKp,
                defaultConfig({
                    config: {
                        classes: generateClasses(formkitTheme)
                    },
                    locales: { nl },
                    locale: "nl",
                    plugins: [createAutoAnimatePlugin()]
                })
            )
            .use(
                createGtm({
                    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
                    enabled: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED === "true" && import.meta.env.PROD,
                    debug: import.meta.env.NODE_ENV !== "production"
                })
            )
            .mount(el);
    }
});
